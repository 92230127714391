import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { useParams, useHistory } from 'react-router-dom';
import BaseAPI from '#helpers/base-api.js';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { changeLayoutTitle } from '../../redux/actions.js';
import EdgeToast from '#components/EdgeToast.js';
import { Table, Badge, Modal, ModalHeader, ModalBody, Card, Button, CardText, Label, Input } from 'reactstrap';
import ConfirmModal from '#components/ConfirmModal.js';
import { GalileoModifyAction } from '#constants/enums.js';
import ActivateCardModal from './components/ActivateCardModal.js';
import CardLimits from './components/CardLimits.js';
import Loader from '#components/Loader.js';

const GalileoCard = ({ cardUrl, freezeInfo }) => {
    if (cardUrl) {
        return (
            <Card className={`view-card mb-0 mr-0`}>
                <img src={cardUrl} alt="Galileo Betting Card" />
                {freezeInfo?.status === 'Frozen' && (
                    <div className="mt-3 text-center">
                        <CardText>
                            <strong>Date Frozen:</strong> {freezeInfo.start_date}
                        </CardText>
                        <CardText>
                            <strong>Freeze End Date:</strong> {freezeInfo.end_date}
                        </CardText>
                    </div>
                )}
            </Card>
        );
    } else {
        return <h2>Loading...</h2>;
    }
};

const GalileoUserDetailsPage = () => {
    const ebAdminClient = new BaseAPI();
    const { user_id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState('success');
    const [toastTitle, setToastTitle] = useState('');
    const [toastContent, setToastContent] = useState('');
    const toggleToast = () => setShowToast(!showToast);
    const [galileoCards, setGalileoCards] = useState([]);
    const [cardFreezeDetails, setCardFreezeDetails] = useState({});
    const [showNumber, setShowNumber] = useState(true);
    const history = useHistory();
    const [confirmModal, setConfirmModal] = useState({ isOpen: false, body: () => null });
    const [cardViewModalOpen, setCardViewModalOpen] = useState(false);
    const [cardUrl, setCardUrl] = useState('');
    const [extendFreezeModal, setExtendFreezeModal] = useState({ isOpen: false, disabled: true });
    const [expediteModal, setExpediteModal] = useState({ isOpen: false, title: 'Expedite Physical Card Creation' });
    const [limitModal, setLimitModal] = useState({
        isOpen: false,
    });
    const [days, setDays] = useState(1);
    const [endDate, setEndDate] = useState('');
    const [activateCardModal, setActivateCardModal] = useState({ isOpen: false });
    const [cardData, setCardData] = useState({ lastFour: '', securityCode: '', month: '', year: '' });
    const [cardLimits, setCardLimits] = useState();

    const onBackToUserDetails = () => {
        history.push(`/users/view/${user_id}`);
    };

    const showMessageToast = (type, title, content) => {
        setToastType(type);
        setToastTitle(title);
        setToastContent(content);
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 10000);
    };

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(changeLayoutTitle('Galileo Details'));
        getGalileoCards(user_id);
    }, [user_id]);

    async function getGalileoCards() {
        setIsLoading(true);
        const response = await ebAdminClient.get(`users/${user_id}/galileo-cards`);
        if (response.data) {
            const resp = await ebAdminClient.get(`users/${user_id}/galileo-cards/limits`);
            console.log(resp);
            setGalileoCards(response.data);
            setCardLimits(() => {
                const limits = resp.data.reduce((acc, card) => {
                    // Ensure both daily and monthly fields exist
                    if (!acc.daily) acc.daily = {};
                    if (!acc.monthly) acc.monthly = {};

                    // Check each element in auth_controls to determine if it's daily or monthly
                    card?.accountLimits?.auth_controls?.forEach((control) => {
                        const limitControlId = control.control_id;
                        const limitAmount = control.amount;
                        const limitAmountUsed = control.amount_used;
                        const limitAmountAvailable = control.amount_available;

                        // Determine limit type based on control ID
                        if (limitControlId === 3) {
                            // Control ID 3 is daily
                            acc.daily[card.prnNumber] = {
                                controlId: limitControlId,
                                amount: limitAmount,
                                amountUsed: limitAmountUsed,
                                amountAvailable: limitAmountAvailable,
                            };
                        } else if (limitControlId === 8) {
                            // Control ID 8 is monthly
                            acc.monthly[card.prnNumber] = {
                                controlId: limitControlId,
                                amount: limitAmount,
                                amountUsed: limitAmountUsed,
                                amountAvailable: limitAmountAvailable,
                            };
                        }
                    });

                    return acc;
                }, {});

                console.log('Limits by type: ', limits);
                return limits;
            });
            setIsLoading(false);
            console.log('Galileo cards', response.data);
        } else {
            console.log('No Galileo cards found for this user', user_id);
            setIsLoading(false);
        }
    }

    const getStatusBadgeColor = (status) => {
        switch (status) {
            case 'Y':
                return 'success';
            case 'L':
                return 'warning';
            case 'N':
                return 'success';
            case 'X':
                return 'secondary';
            default:
                return 'success';
        }
    };

    const getStatusLabel = (status) => {
        switch (status) {
            case 'Y':
                return 'Active';
            case 'L':
                return 'Lost/Stolen';
            case 'N':
                return 'Normal';
            case 'X':
                return 'Set to Emboss';
            default:
                return 'Unknown';
        }
    };

    const handleViewCard = async (cardId, card) => {
        try {
            if (card.freeze_info.status === 'Frozen') {
                setCardFreezeDetails(card.freeze_info);
            }
            const response = await ebAdminClient.get(`users/${user_id}/cards/${cardId}/view`);
            console.log('response', response);
            if (response.data?.card_image_url) {
                setCardUrl(response.data.card_image_url.url);
                setCardViewModalOpen(true);
            } else {
                showMessageToast('error', 'Error', 'Unable to load card details');
            }
        } catch (error) {
            console.error('Error fetching card details:', error);
            showMessageToast('error', 'Error', 'Failed to load card details');
        }
    };

    const isExpressShippingEnabled = (account) => {
        const feature = account.accountFeatures.features.find((feature) => feature.type === 5);
        return feature?.value !== 'N';
    };

    const handleCardStatusChange = async () => {
        switch (confirmModal.type) {
            case 'Unfreeze': {
                await ebAdminClient.post('cards/modify-status', {
                    prn: confirmModal.card.card_id,
                    type: GalileoModifyAction.UNFREEZE_CARD,
                });
                // update state
                await getGalileoCards();
                setConfirmModal({ isOpen: false, body: () => null });
                return;
            }

            case 'Freeze': {
                // Freeze for 24 hours.
                await ebAdminClient.post('cards/modify-status', {
                    prn: confirmModal.card.card_id,
                    type: GalileoModifyAction.FREEZE_CARD,
                    days: days || null,
                });
                // update state
                await getGalileoCards();
                setConfirmModal({ isOpen: false, body: () => null });
                setExtendFreezeModal({ isOpen: false, disabled: true });
                return;
            }

            default:
                return;
        }
    };

    const handleEndDateChange = (e) => {
        if (e.target.value > 0) {
            setExtendFreezeModal((prevState) => ({ ...prevState, disabled: false }));
        } else {
            setExtendFreezeModal((prevState) => ({ ...prevState, disabled: true }));
        }
        setDays(e.target.value);
        const newEndDate = moment().add(e.target.value, 'days').format('MMMM Do, YYYY');
        setEndDate(newEndDate);
    };
    const handleExtendFreezeButton = (e) => {
        setConfirmModal((prevState) => ({ ...prevState, isOpen: false }));
        setExtendFreezeModal((prevState) => {
            return {
                ...prevState,
                isOpen: true,
                disabled: true,
                title: 'Freeze Card (extended)',
            };
        });
    };

    const handleConfirmModal = (e, card) => {
        const type = e.target.innerText;
        const now = new Date();
        const futureDate = new Date(now.getTime() + 24 * 60 * 60 * 1000).toLocaleString(); // add 24 hours
        setConfirmModal({
            isOpen: true,
            type,
            title: `${type} Card`,
            body: () => {
                return (
                    <>
                        <p>
                            Are you sure you want to <strong>{type.toUpperCase()}</strong> this card?
                        </p>
                        {type === 'Freeze' ? (
                            <>
                                <>
                                    <p>
                                        The card will be frozen for <strong>24 hours (until {futureDate})</strong>
                                    </p>
                                    <div className="text-center">
                                        <Button color="primary" onClick={handleExtendFreezeButton}>
                                            Extend Freeze
                                        </Button>
                                    </div>
                                </>
                            </>
                        ) : (
                            <></>
                        )}
                    </>
                );
            },
            card,
        });
    };

    const handleCreatePhysicalCard = async (expediteCard) => {
        showMessageToast(
            'info',
            'Processing...',
            `Adding New Account to ${galileoCards?.customer?.first_name} ${galileoCards?.customer?.last_name}`
        );
        const resp = await ebAdminClient.post(`users/${user_id}/accounts`, {
            prn: galileoCards?.accounts?.[0]?.pmt_ref_no,
            expediteCard,
        });
        // update state
        await getGalileoCards();
        setExpediteModal({ ...expediteModal, isOpen: false });
        showMessageToast('success', 'Success!', `Account added successfully with new PRN: ${resp.data.prnNumber}`);
    };

    const handleCreateDigitalCard = async () => {
        showMessageToast(
            'info',
            'Processing...',
            `Adding New Digital Card to ${galileoCards?.customer?.first_name} ${galileoCards?.customer?.last_name}`
        );
        const resp = await ebAdminClient.post(`users/${user_id}/add-digital-card`, {
            prn: galileoCards?.accounts?.[0]?.pmt_ref_no,
        });
        // update state
        await getGalileoCards();
        showMessageToast('success', 'Success!', `Account added successfully with new PRN: ${resp.data.prnNumber}`);
    };

    const handleActivateCard = async (e) => {
        e.preventDefault();
        const reqBody = {
            cardId: activateCardModal.cardId,
            cardExpiryDate: `${cardData.year}-${cardData.month}`,
            cardSecurityCode: cardData.securityCode,
            cardNumberLastFour: cardData.lastFour,
        };
        try {
            const resp = await ebAdminClient.post('cards/activate-card', reqBody);
            if (resp.ok) {
                setActivateCardModal({ isOpen: false });
                showMessageToast('success', 'Card Activated', 'Card has been successfully activated!');
                await getGalileoCards();
            }
        } catch (err) {
            setActivateCardModal({ isOpen: false });
            showMessageToast('danger', 'Card Activation Failed', 'Failed to activate card.');
            console.error(err);
        }
    };

    const getCardType = (prodId) => {
        switch (prodId) {
            case '1975':
                return 'Virtual';
            case '3376':
                return 'Physical';
            default:
                return;
        }
    };
    const changeShippingStatus = async (account) => {
        const resp = await ebAdminClient.post('cards/update-shipping-status', {
            prn: account.pmt_ref_no,
            expressShipping: true,
        });
        await getGalileoCards();
    };

    const handleRemoveLimit = async () => {
        try {
            const reqBody = {
                prnNumber: limitModal.limit.prnNumber,
                controlId: limitModal.limit.controlId,
            };
            console.log(reqBody);
            const resp = await ebAdminClient.post(`users/${user_id}/galileo-cards/remove-limit`, reqBody);
            if (resp.data.success) {
                showMessageToast('success', `Remove limit`, `Limit has been removed.`);
            } else {
                showMessageToast('danger', 'Remove Limit', 'An error occured while removing limit.')
            }
            setLimitModal({isOpen: false})
            await getGalileoCards();
        } catch (err) {
            console.error(err);
        }
    };

    const handleSetLimit = async (type, card) => {
        const { amount } = type === 'daily' ? cardLimits.daily[card.prnNumber] : cardLimits.monthly[card.prnNumber];
        const reqBody = {
            userId: user_id,
            prnNumber: card.prnNumber,
            type,
            amount: parseFloat(amount),
        };
        try {
            if (isNaN(reqBody.amount) || reqBody.amount < 0) {
                showMessageToast('danger', `Set limit`, `The limit must be equal or greater than 0.`);
            } else {
                const resp = await ebAdminClient.post(`users/${user_id}/galileo-cards/limits`, reqBody);
                showMessageToast('success', `Set limit`, `Limit has been updated.`);
                await getGalileoCards();
            }
        } catch (e) {
            console.error(e);
        }
    };

    if (isLoading)
        return (
            <>
                <Loader title={'Fetching Galileo Details'} />
            </>
        );

    return (
        <div className="py-3">
            <Helmet>
                <title>Galileo Details</title>
            </Helmet>
            {/* Enhanced User Details Section */}
            <Card className="mb-4">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h2 className="mb-0">Galileo Details</h2>
                        <button className="btn btn-warning" onClick={onBackToUserDetails}>
                            Back To User Details
                        </button>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <h6 className="text-muted mb-1">Full Name</h6>
                            <h5>
                                {galileoCards?.customer?.first_name} {galileoCards?.customer?.last_name}
                            </h5>
                        </div>
                        <div className="col-md-6 mb-3">
                            <h6 className="text-muted mb-1">PRN</h6>
                            <h5>{galileoCards?.accounts?.[0]?.pmt_ref_no || 'N/A'}</h5>
                        </div>
                        <div className="col-md-6 mb-3">
                            <h6 className="text-muted mb-1">Account Number</h6>
                            <h5>{galileoCards?.accounts?.[0]?.galileo_account_number || 'N/A'}</h5>
                        </div>
                        <div className="col-md-6 mb-3">
                            <h6 className="text-muted mb-1">Total Cards</h6>
                            <h5>
                                {galileoCards?.accounts?.reduce((total, account) => total + account.cards.length, 0) ||
                                    0}
                            </h5>
                        </div>
                    </div>
                    <Button outline color={'primary'} className="mb-2 mr-3" onClick={handleCreateDigitalCard}>
                        Create Digital Card
                    </Button>
                    <Button
                        color={'primary'}
                        className="mb-2"
                        onClick={() => setExpediteModal({ ...expediteModal, isOpen: true })}>
                        Create Physical Card
                    </Button>
                </div>
            </Card>
            <h2 className="text-center">View Shared Accounts</h2>
            {/* Cards Table Section */}
            {galileoCards?.accounts?.map((account) => (
                <div key={account.pmt_ref_no} className="mt-4">
                    <div>
                        <h4>Account PRN #{account.pmt_ref_no} </h4>
                    </div>
                    {account.cards.map((card) => {
                        // add PRN number to card for data structure for reading card limits
                        card.prnNumber = account.pmt_ref_no;
                        return (
                            <>
                                <CardLimits
                                    setLimitModal={setLimitModal}
                                    limitModal={limitModal}
                                    setLimitHandler={handleSetLimit}
                                    setCardLimits={setCardLimits}
                                    cardLimits={cardLimits}
                                    card={card}
                                />
                            </>
                        );
                    })}
                    <Table striped responsive className="mb-2">
                        <thead>
                            <tr>
                                <th>Card Number</th>
                                <th>Type</th>
                                <th>Status</th>
                                <th>Created Date</th>
                                <th className="text-center">Express Shipping</th>
                                <th className="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {account.cards.map((card) => (
                                <tr key={card.card_id}>
                                    <td>{card.card_number}</td>
                                    <td>{getCardType(account.product_id)}</td>
                                    <td>
                                        {card.freeze_info?.status === 'Frozen' ? (
                                            <Badge
                                                className="p-1"
                                                color={'info'}
                                                style={{ color: 'white', fontSize: '1em' }}>
                                                Frozen
                                            </Badge>
                                        ) : (
                                            <Badge
                                                className="p-1"
                                                style={{ color: 'white', fontSize: '1em' }}
                                                color={getStatusBadgeColor(card.card_status)}>
                                                {getStatusLabel(card.card_status)}
                                            </Badge>
                                        )}
                                    </td>
                                    <td>{account.start_date}</td>
                                    <td className="text-center">
                                        {/* <FormGroup switch>
                                            <Input type="switch" role="switch" id="expressShipping" name="expressShipping" checked={isExpressShippingEnabled(account)} onChange={() => changeShippingStatus(account)} />
                                        </FormGroup> */}
                                        {isExpressShippingEnabled(account) ? (
                                            <p>Yes</p>
                                        ) : (
                                            <Button
                                                color="success"
                                                outline
                                                onClick={() => changeShippingStatus(account)}>
                                                Expedite
                                            </Button>
                                        )}
                                    </td>
                                    <td className="text-center">
                                        {process.env.REACT_APP_SIMULATOR === 'true' && (
                                            <button
                                                onClick={() =>
                                                    history.push(`/simulator/galileo?prn=${account.pmt_ref_no}`)
                                                }
                                                className="btn btn-sm btn-secondary mr-2">
                                                Go to Simulator
                                            </button>
                                        )}
                                        {card.card_status === 'N' && (
                                            <button
                                                className="btn btn-sm btn-primary"
                                                onClick={() => handleViewCard(card.card_id, card)}>
                                                View Card
                                            </button>
                                        )}
                                        {account.product_id === '3376' && card.card_status === 'Y' && (
                                            <button
                                                className="btn btn-sm btn-warning ml-2"
                                                onClick={() =>
                                                    setActivateCardModal({ isOpen: true, cardId: card.card_id })
                                                }>
                                                Activate Card
                                            </button>
                                        )}

                                        {card.freeze_info?.status === 'Frozen' ? (
                                            <button
                                                className="btn btn-sm btn-danger ml-2"
                                                onClick={(e) => handleConfirmModal(e, card)}>
                                                Unfreeze
                                            </button>
                                        ) : (
                                            <button
                                                className="btn btn-sm btn-info ml-2"
                                                onClick={(e) => handleConfirmModal(e, card)}>
                                                Freeze
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            ))}

            {/* Card View Modal */}
            <Modal isOpen={cardViewModalOpen} toggle={() => setCardViewModalOpen(false)}>
                <ModalHeader toggle={() => setCardViewModalOpen(false)}>Card Details</ModalHeader>
                <ModalBody>
                    <GalileoCard
                        cardUrl={cardUrl}
                        issueCard={{ cardStatus: 'Y' }}
                        showNumber={showNumber}
                        setShowNumber={setShowNumber}
                        freezeInfo={cardFreezeDetails}
                    />
                </ModalBody>
            </Modal>
            {/* Confirm for Freezing/Unfreezing */}
            <ConfirmModal
                isOpen={confirmModal.isOpen}
                onClose={() => setConfirmModal({ ...confirmModal, isOpen: false })}
                onYes={handleCardStatusChange}
                modalTitle={confirmModal.title}>
                {confirmModal?.body()}
            </ConfirmModal>
            <ConfirmModal
                isOpen={extendFreezeModal.isOpen}
                onClose={() => setExtendFreezeModal({ ...extendFreezeModal, isOpen: false })}
                onYes={handleCardStatusChange}
                modalTitle={extendFreezeModal.title}
                disabled={extendFreezeModal.disabled}>
                <>
                    {endDate && (
                        <p>
                            The card will be frozen for{' '}
                            <strong>
                                {days} day(s) (until {endDate})
                            </strong>
                            .
                        </p>
                    )}
                    <Label for="days">Days: </Label>
                    <Input type="number" name="days" id="days" onChange={handleEndDateChange} />
                </>
            </ConfirmModal>
            <ConfirmModal
                isOpen={expediteModal.isOpen}
                onClose={(e) => handleCreatePhysicalCard(false)}
                onYes={(e) => handleCreatePhysicalCard(true)}
                modalTitle={expediteModal.title}>
                <p>Do you want to expedite the creation of this physical card?</p>
            </ConfirmModal>
            <ConfirmModal
                isOpen={limitModal.isOpen}
                modalTitle="Remove Responsible Betting Limit"
                onYes={handleRemoveLimit}
                onClose={() => setLimitModal({ isOpen: false })}>
                Are you sure you want to remove this limit?
            </ConfirmModal>
            <ActivateCardModal
                isOpen={activateCardModal.isOpen}
                toggle={() => setActivateCardModal({ isOpen: false })}
                handleSubmit={handleActivateCard}
                cardData={cardData}
                setCardData={setCardData}
            />
            {/* Toast Notifications */}
            <EdgeToast
                isOpen={showToast}
                toggle={toggleToast}
                type={toastType}
                title={toastTitle}
                content={toastContent}
            />
        </div>
    );
};

export default GalileoUserDetailsPage;
