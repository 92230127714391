import React from 'react';
import { Button, Card, CardBody, Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";

const AddMoneyToCard = ({ simulatorEnabled, depositFormik, prnNumber }) => {
    return (
        <Card className="shadow border-0 mt-4">
            <CardBody>
                <h4 className="header-title">Add Money to Card</h4>
                <Form onSubmit={depositFormik.handleSubmit}>
                    <FormGroup className="position-relative">
                        <Label for="amount">Amount</Label>
                        <Input
                            id="amount"
                            name="amount"
                            type="number"
                            placeholder="Amount"
                            onChange={depositFormik.handleChange}
                            onBlur={depositFormik.handleBlur}
                            value={depositFormik.values.amount}
                            invalid={depositFormik.errors.amount && depositFormik.touched.amount}
                        />
                        {depositFormik.errors.amount && depositFormik.touched.amount ? (
                            <FormFeedback
                                invalid={depositFormik.errors.amount && depositFormik.touched.amount}
                                tooltip>
                                {depositFormik.errors.amount}
                            </FormFeedback>
                        ) : null}
                    </FormGroup>
                    <Button type="submit" color="dark" disabled={
                        depositFormik.errors.amount || 
                        prnNumber === '' ||
                        !simulatorEnabled
                        }>
                        Submit
                    </Button>
                </Form>
            </CardBody>
        </Card>
    )
}

export default AddMoneyToCard;