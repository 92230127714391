import React from 'react';
import { Col, Row } from 'reactstrap';

const SimpleCardLimits = ({ limits }) => {
    // Destructure the daily and monthly properties
    const { daily, monthly } = limits;

    // Get a list of unique card IDs (assuming both daily and monthly objects have the same keys)
    const cardIds = Array.from(new Set([...Object.keys(daily || {}), ...Object.keys(monthly || {})]));

    return (
        <div>
            <hr />
            <p>
                <strong>Card Limits: {Object.keys(daily).length === 0 && Object.keys(monthly).length === 0 && <span>None</span>}</strong>
            </p>
            {Object.keys(daily).length !== 0 && Object.keys(monthly).length !== 0 && (
                <Row>
                    {cardIds.map((cardId) => {
                        const dailyLimit = daily && daily[cardId];
                        const monthlyLimit = monthly && monthly[cardId];

                        return (
                            <Col key={cardId}>
                                <p>PRN: {cardId}</p>
                                <p>
                                    Daily:{' '}
                                    {dailyLimit ? `$${dailyLimit.amountUsed} / $${dailyLimit.amount}` : 'Not Set'}
                                </p>
                                <p>
                                    Monthly:{' '}
                                    {monthlyLimit ? `$${monthlyLimit.amountUsed} / $${monthlyLimit.amount}` : 'Not Set'}
                                </p>
                            </Col>
                        );
                    })}
                </Row>
            )}
        </div>
    );
};

export default SimpleCardLimits;
