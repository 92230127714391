import React from 'react';
import { Button, Card, CardBody, Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";

const AdjustFundsOnCard = ({ simulatorEnabled, adjustmentFormik, prnNumber }) => {
    return (
        <Card className="shadow border-0 mt-4">
            <CardBody>
                <h4 className="header-title">Adjust Funds on Card</h4>
                <Form onSubmit={adjustmentFormik.handleSubmit}>
                    <FormGroup className="position-relative">
                        <Input
                            name="debitCreditIndicator"
                            type="select"
                            placeholder="Enter Your Indicator"
                            className="form-control"
                            value={adjustmentFormik.values.debitCreditIndicator}
                            onChange={adjustmentFormik.handleChange}
                            onBlur={adjustmentFormik.handleBlur}
                            invalid={
                                adjustmentFormik.errors.debitCreditIndicator &&
                                adjustmentFormik.touched.debitCreditIndicator
                            }>
                            <option value="C">Credit</option>
                            <option value="D">Debit</option>
                        </Input>
                        {adjustmentFormik.errors.debitCreditIndicator &&
                            adjustmentFormik.touched.debitCreditIndicator ? (
                            <FormFeedback
                                invalid={
                                    adjustmentFormik.errors.debitCreditIndicator &&
                                    adjustmentFormik.touched.debitCreditIndicator
                                }
                                tooltip>
                                {adjustmentFormik.errors.debitCreditIndicator}
                            </FormFeedback>
                        ) : null}
                    </FormGroup>
                    <FormGroup className="position-relative">
                        <Label for="amount">Amount</Label>
                        <Input
                            id="amount"
                            name="amount"
                            label="Amount"
                            type="number"
                            placeholder="Amount"
                            value={adjustmentFormik.values.amount}
                            onChange={adjustmentFormik.handleChange}
                            onBlur={adjustmentFormik.handleBlur}
                            invalid={adjustmentFormik.errors.amount && adjustmentFormik.touched.amount}
                        />
                        {adjustmentFormik.errors.amount && adjustmentFormik.touched.amount ? (
                            <FormFeedback
                                invalid={
                                    adjustmentFormik.errors.amount && adjustmentFormik.touched.amount
                                }
                                tooltip>
                                {adjustmentFormik.errors.amount}
                            </FormFeedback>
                        ) : null}
                    </FormGroup>
                    <Button
                        type="submit"
                        color="dark"
                        disabled={
                            adjustmentFormik.errors.amount ||
                            adjustmentFormik.errors.debitCreditIndicator  || 
                            prnNumber === '' ||
                            !simulatorEnabled
                        }>
                        Submit
                    </Button>
                </Form>
            </CardBody>
        </Card>
    )
}

export default AdjustFundsOnCard;