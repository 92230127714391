import { Button, Col, Input, Label, Row } from 'reactstrap';
import RadialBarChart from './RadialBarChart';

const CardLimits = ({ limitModal, setLimitModal, setLimitHandler, setCardLimits, cardLimits, card }) => {
    const handleDailyLimit = (e, card) => {
        e.persist();
        setCardLimits((prevLimits) => {
            return {
                ...prevLimits,
                daily: {
                    ...prevLimits.daily,
                    [card.prnNumber]: {
                        ...prevLimits.daily[card.prnNumber],
                        amount: e.target.value,
                    },
                },
            };
        });
    };

    const handleMonthlyLimit = (e, card) => {
        e.persist();
        setCardLimits((prevLimits) => {
            return {
                ...prevLimits,
                monthly: {
                    ...prevLimits.monthly,
                    [card.prnNumber]: {
                        ...prevLimits.monthly[card.prnNumber],
                        amount: e.target.value,
                    },
                },
            };
        });
    };
    return (
        <Row className="justify-content-center">
            <Col lg={4} className="mb-4">
                <RadialBarChart
                    title="Daily Spending Limit"
                    limit={cardLimits?.daily[card.prnNumber]?.amount ? cardLimits?.daily[card.prnNumber]?.amount : 0}
                    usage={
                        cardLimits?.daily[card.prnNumber]?.amountUsed
                            ? cardLimits?.daily[card.prnNumber]?.amountUsed
                            : 0
                    }
                />
                <div className="text-center">
                    <Label>Daily Spending Limit</Label>
                    <div className="d-flex align-items-center justify-content-between">
                        <Input
                            type="text"
                            value={cardLimits?.daily[card.prnNumber]?.amount}
                            onChange={(e) => handleDailyLimit(e, card)}
                            className="mr-2"
                        />
                        <Button className="text-nowrap" onClick={() => setLimitHandler('daily', card)}>
                            Set Limit
                        </Button>
                    </div>
                    {cardLimits?.daily[card.prnNumber] && (
                        <div
                            className="text-center mt-3"
                            onClick={() =>
                                setLimitModal({
                                    ...limitModal,
                                    isOpen: true,
                                    limit: {
                                        prnNumber: card.prnNumber,
                                        controlId: cardLimits?.daily[card.prnNumber].controlId,
                                    },
                                })
                            }>
                            <Button color="danger">Remove Limit</Button>
                        </div>
                    )}
                </div>
            </Col>
            <Col lg={4} className="mb-4">
                <RadialBarChart
                    title="Monthly Spending Limit"
                    limit={
                        cardLimits?.monthly[card.prnNumber]?.amount ? cardLimits?.monthly[card.prnNumber]?.amount : 0
                    }
                    usage={
                        cardLimits?.monthly[card.prnNumber]?.amountUsed
                            ? cardLimits?.monthly[card.prnNumber]?.amountUsed
                            : 0
                    }
                />
                <div className="text-center">
                    <Label>Monthly Spending Limit</Label>
                    <div className="d-flex align-items-center justify-content-between">
                        <Input
                            type="text"
                            value={cardLimits?.monthly[card.prnNumber]?.amount}
                            onChange={(e) => handleMonthlyLimit(e, card)}
                            className="mr-2"
                        />
                        <Button className="text-nowrap" onClick={() => setLimitHandler('monthly', card)}>
                            Set Limit
                        </Button>
                    </div>
                    {cardLimits?.monthly[card.prnNumber] && (
                        <div
                            className="text-center mt-3"
                            onClick={() =>
                                setLimitModal({
                                    ...limitModal,
                                    isOpen: true,
                                    limit: {
                                        prnNumber: card.prnNumber,
                                        controlId: cardLimits?.monthly[card.prnNumber].controlId,
                                    },
                                })
                            }>
                            <Button color="danger">Remove Limit</Button>
                        </div>
                    )}
                </div>
            </Col>
        </Row>
    );
};

export default CardLimits;
