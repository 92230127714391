import React, { useState, useEffect, useCallback } from 'react';
import {
    Row,
    Col,
    Button,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Input
} from 'reactstrap';
import { NumericFormat } from 'react-number-format';
import BaseAPI from '../../../helpers/base-api';
import CloseModalButton from '#components/CloseModalButton.js';

const baseAPI = new BaseAPI();

const ACHSweepModal = ({ isOpen, onClose, bankData, userData, debitAmount, creditAmount, showMessageToast }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [amount, setAmount] = useState((debitAmount || creditAmount) || 0);
    const [selectedBank, setSelectedBank] = useState(bankData[0]);

    const onSweep = async () => {
        const data = {
            amount: amount,
            account_id: selectedBank,
            userId: userData,
            isSweep: true,
        }
        try {
            const resp = await baseAPI.post('bank/ach-sweep', data)
            onClose()
            showMessageToast('info', 'ACH Sweep Result', `ACH Sweep Status: ${resp.data.status}`)
        } catch (err) {
            onClose()
            showMessageToast('danger', 'ACH Sweep Error', `${err.message}`)
            console.error(err)
        }

    }

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={onClose} backdrop="static">
                <ModalHeader toggle={onClose} close={<CloseModalButton onClose={onClose} />}>Initiate ACH Sweep</ModalHeader>
                <ModalBody>

                    <Col>
                        <FormGroup>
                            <Label for="amount">Amount</Label>
                            <Input placeholder={'0.00'} type="text" name="amount" id="amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="source">Source</Label>
                            <Input type="select" name="source" id="source" className="form-control" value={selectedBank} onChange={(e) => setSelectedBank(e.target.value)}>
                                <option value="">Select Account</option>
                                {bankData.map((item, index) => (
                                    <option key={index} value={item.id}>
                                        {item.metaAccountName} - {item.metaAccountMask}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for='destination'>Destination</Label>
                            <Input type="text" value="Operating Account - 1172" disabled name="destination" id="destination" onChange={(e) => console.log(e.target.value)} />
                        </FormGroup>
                    </Col>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn-default" color="primary" disabled={isLoading || amount === 0} onClick={onSweep}>Initiate</Button>
                    {' '}
                    <Button color="secondary" onClick={onClose} disabled={isLoading}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment >
    )
};

export default ACHSweepModal;
