import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardText,
    CardHeader,
    Button,
    ButtonGroup,
    Badge,
    Popover,
    UncontrolledPopover,
    PopoverHeader,
    PopoverBody,
} from 'reactstrap';
import Enums from '../../../constants/enums';
import { formatDate } from '#helpers/displayUtils.js';
import CopyButton from '#components/CopyButton.js';
import UserDetailsActionBar from './userDetailsActionBar';
import SimpleCardLimits from './SimpleCardLimits';

const BalanceDisplay = ({ title, amount }) => {
    let fontStyle;

    if (title === 'Debit Amount' && amount !== 0) {
        fontStyle = { color: 'crimson' };
    }

    if (title === 'Credit Amount' && amount !== 0) {
        fontStyle = { color: 'green' };
    }
    return (
        <div className="d-flex flex-column align-items-center mx-2 mb-2 mb-md-0">
            <small className="text-muted">{title}</small>
            <strong className="h5 mb-0">
                <NumericFormat
                    value={amount || 0}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    decimalScale={2}
                    style={fontStyle}
                />
            </strong>
        </div>
    );
};

const UserDetails = ({
    userData,
    alloyHostedLink,
    onChangeCardStatus,
    onAdjustBalance,
    onAddToCRB,
    onAddToGalileo,
}) => {
    const history = useHistory();
    const displayValue = (value) => value || 'N/A';
    const onEditUser = () => {
        history.push(`/users/edit/${userData.id}`);
    };

    const goBack = () => {
        history.push('/users');
    };
    const [showPopover, setShowPopover] = useState(false);
    const getUserStatusBadgeColor = (status) => {
        switch (status) {
            case 'active':
                return 'success';
            case 'cooldown':
                return 'info';
            default:
                return 'warning';
        }
    };

    const getStatusStyle = (status, type) => {
        const baseStyle = {
            padding: '2px 5px',
            borderRadius: '3px',
            display: 'inline-block',
            marginLeft: '5px',
        };

        switch (type) {
            case 'user':
                return status !== 'active' ? { ...baseStyle, backgroundColor: '#ffcccb' } : baseStyle;
            case 'galileoAccount':
            case 'galileoCard':
                return status !== 'N' ? { ...baseStyle, backgroundColor: '#fff2cc' } : baseStyle;
            case 'crb':
                return status !== 'Active' ? { ...baseStyle, backgroundColor: '#fff2cc' } : baseStyle;
            case 'ofac':
            case 'pepScan':
                return status !== 'Clear' ? { ...baseStyle, backgroundColor: '#fff2cc' } : baseStyle;
            default:
                return baseStyle;
        }
    };

    return (
        <Container fluid className="user-details">
            <Button color="secondary" onClick={goBack} className="mb-4">
                &lt; Back to Users
            </Button>

            <UserDetailsActionBar
                onEditUser={onEditUser}
                onAdjustBalance={onAdjustBalance}
                onChangeCardStatus={onChangeCardStatus}
                onAddToCRB={onAddToCRB}
                onAddToGalileo={onAddToGalileo}
                userData={userData}
            />

            <Card className="mb-4">
                <CardHeader>
                    <Row className="align-items-center">
                        <Col xs={12} md={4} className="mb-3 mb-md-0">
                            <h3 className="mb-1">{userData.name}</h3>
                            <div className="d-flex align-items-center">
                                <span className="text-muted">{userData.email}</span>
                                <CopyButton textToCopy={userData.email} color="link" className="ml-2" />
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="mb-3 mb-md-0">
                            <div className="d-flex flex-wrap justify-content-around">
                                <BalanceDisplay title="Cashback Earned" amount={userData.cashbackEarned} />
                                <BalanceDisplay title="EDGE Balance" amount={userData.boostBalance} />
                                <BalanceDisplay title="Galileo Balance" amount={userData.card?.balance} />
                                {/* <BalanceDisplay title="Debit Amount" amount={userData.debitAmount} /> */}
                                {/* <BalanceDisplay title="Credit Amount" amount={userData.creditAmount} /> */}
                            </div>
                        </Col>
                        <Col xs={12} md={2} className="text-md-right text-center">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <Badge
                                    className="p-1"
                                    style={{ color: 'white', fontSize: '1em' }}
                                    color={getUserStatusBadgeColor(userData.status)}
                                    pill>
                                    {userData?.status?.toUpperCase()}
                                </Badge>
                                {userData?.status === 'cooldown' ? (
                                    <>
                                        <p className="mt-2 mb-0">
                                            Start Date: {new Date(userData?.cooldownStartDate).toLocaleDateString()}
                                        </p>
                                        <p className="mb-0">
                                            End Date: {new Date(userData?.cooldownEndDate).toLocaleDateString()}
                                        </p>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md={4} className="mb-4">
                            <h4>Personal Information</h4>
                            <Card>
                                <CardBody>
                                    <CardText>
                                        <strong>Address:</strong> {displayValue(userData.address)}
                                    </CardText>
                                    <CardText>
                                        <strong>City:</strong> {displayValue(userData.city)}
                                    </CardText>
                                    <CardText>
                                        <strong>State:</strong> {displayValue(userData.state)}
                                    </CardText>
                                    <CardText>
                                        <strong>Zip:</strong> {displayValue(userData.zip)}
                                    </CardText>
                                    <CardText>
                                        <strong>DOB:</strong> {displayValue(userData.birthDay)}
                                    </CardText>
                                    <CardText>
                                        <strong>Phone:</strong> {displayValue(userData.phone)}
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4} className="mb-4">
                            <h4>Account Status</h4>
                            <Card>
                                <CardBody>
                                    <CardText>
                                        <strong>KYC Status:</strong> {displayValue(userData.alloyOutcome)}
                                    </CardText>
                                    <CardText>
                                        <div className="position-relative">
                                            <strong>Setup Status:</strong> {displayValue(userData.setupStatus)}
                                            {userData.setupStatus === 'kyc-in-progress' ? (
                                                <>
                                                    <Badge
                                                        id="Popover1"
                                                        color="info"
                                                        style={{
                                                            height: '1rem',
                                                            width: '1rem',
                                                            color: 'white',
                                                            position: 'absolute',
                                                            top: '0',
                                                            marginLeft: '2px',
                                                            cursor: 'pointer',
                                                        }}
                                                        pill
                                                        onClick={() => setShowPopover(true)}>
                                                        ?
                                                    </Badge>
                                                    <Popover
                                                        isOpen={showPopover}
                                                        placement="right"
                                                        target="Popover1"
                                                        toggle={() => setShowPopover(!showPopover)}>
                                                        <PopoverHeader>
                                                            <strong>Stage</strong>
                                                        </PopoverHeader>
                                                        <PopoverBody>
                                                            {userData.alloyStatus === 'pending_step_up' ? (
                                                                <>
                                                                    <p>User has not completed manual verification.</p>
                                                                    <p>
                                                                        <strong>Link to Application:</strong>
                                                                        <hr className="m-1" /> {alloyHostedLink}
                                                                        <CopyButton
                                                                            textToCopy={alloyHostedLink}
                                                                            color="link"
                                                                            className="ml-1"
                                                                        />
                                                                    </p>
                                                                </>
                                                            ) : (
                                                                <>Waiting for documents to be verified.</>
                                                            )}
                                                        </PopoverBody>
                                                    </Popover>
                                                    <p className="ml-2">
                                                        <strong>Stage: </strong>{' '}
                                                        {userData.alloyStatus === 'pending_step_up'
                                                            ? 'Waiting on customer'
                                                            : 'Waiting on manual review'}
                                                    </p>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </CardText>
                                    <CardText>
                                        <strong>2FA Enabled:</strong>{' '}
                                        {userData.require2FA ? (
                                            <i style={{ color: 'green' }} className="ml-2 fa-solid fa-circle-check"></i>
                                        ) : (
                                            <i style={{ color: 'red' }} className="ml-2 fa-solid fa-circle-xmark"></i>
                                        )}
                                    </CardText>
                                    <CardText>
                                        <strong>User Tier:</strong> {displayValue(userData.boostLevel)}
                                    </CardText>
                                    <CardText>
                                        <strong>Affiliate ID:</strong> {displayValue(userData.affiliateId)}
                                    </CardText>
                                    <CardText>
                                        <strong>Offer ID:</strong> {displayValue(userData.offerId)}
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col md={4} className="mb-4">
                            <h4>Account History</h4>
                            <Card>
                                <CardBody>
                                    <CardText>
                                        <strong>Created On:</strong> {formatDate(userData.createdAt)}
                                    </CardText>
                                    <CardText>
                                        <strong>Last Login:</strong> {formatDate(userData.last_login)}
                                    </CardText>
                                    <CardText>
                                        <strong>Last Update:</strong> {formatDate(userData.updatedAt)}
                                    </CardText>
                                    <CardText>
                                        <strong>Last Login IP:</strong> {displayValue(userData.login_ip)}
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Row>
                <Col md={6}>
                    <Card className="mb-4">
                        <CardHeader>
                            <h4 className="mb-0">Galileo Details</h4>
                        </CardHeader>
                        <CardBody>
                            {!userData.card?.prnNumber ? (
                                <p>No Galileo account associated</p>
                            ) : (
                                <>
                                    <CardText>
                                        <strong>PRN:</strong> {displayValue(userData.card?.prnNumber)}
                                        <CopyButton
                                            textToCopy={userData.card?.prnNumber}
                                            color="secondary"
                                            className="ml-2"
                                        />
                                    </CardText>
                                    <CardText>
                                        <strong>Card Number:</strong> {displayValue(userData.card?.cardNumber)}
                                    </CardText>
                                    <CardText>
                                        <strong>Account Status:</strong>
                                        <span style={getStatusStyle(userData.card?.accountStatus, 'galileoAccount')}>
                                            {userData.card?.accountStatus}
                                        </span>
                                    </CardText>
                                    <CardText>
                                        <strong>Card Status:</strong>
                                        <span style={getStatusStyle(userData.card?.cardStatus, 'galileoCard')}>
                                            {userData.card?.cardStatus}
                                        </span>
                                    </CardText>
                                    <CardText>
                                        <strong>Last Update:</strong> {formatDate(userData.card?.updatedAt)}
                                    </CardText>
                                    {userData.card?.cardStatus !== Enums.GalileoCardStatusText.ACTIVE && (
                                        <CardText>
                                            <strong>Reason for Disabling:</strong>{' '}
                                            {displayValue(userData.card?.reasonForInactive)}
                                        </CardText>
                                    )}
                                    <SimpleCardLimits limits={userData?.limits} />
                                </>
                            )}
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="mb-4">
                        <CardHeader>
                            <h4 className="mb-0">CrossRiver Details</h4>
                        </CardHeader>
                        <CardBody>
                            {!userData.crossRiverCustomer ? (
                                <p>No CrossRiver account associated</p>
                            ) : (
                                <>
                                    <CardText>
                                        <strong>CIF Number:</strong>{' '}
                                        {displayValue(userData.crossRiverCustomer.cifNumber)}
                                    </CardText>
                                    <CardText>
                                        <strong>Status:</strong>
                                        <span style={getStatusStyle(userData.crossRiverCustomer.status, 'crb')}>
                                            {userData.crossRiverCustomer.status}
                                        </span>
                                    </CardText>
                                    <CardText>
                                        <strong>OFAC:</strong>
                                        <span style={getStatusStyle(userData.crossRiverCustomer.ofac, 'ofac')}>
                                            {userData.crossRiverCustomer.ofac}
                                        </span>
                                    </CardText>
                                    <CardText>
                                        <strong>PEP Scan:</strong>
                                        <span style={getStatusStyle(userData.crossRiverCustomer.pepScan, 'pepScan')}>
                                            {userData.crossRiverCustomer.pepScan}
                                        </span>
                                    </CardText>
                                    <CardText>
                                        <strong>Updated On:</strong> {formatDate(userData.crossRiverCustomer.updatedAt)}
                                    </CardText>
                                    <CardText>
                                        <strong>Routing Number:</strong> 021214891
                                    </CardText>
                                    <CardText>
                                        <strong>Virtual Account Number:</strong> 5
                                        {displayValue(userData.card?.prnNumber)}
                                    </CardText>
                                    <CardText>
                                        <strong>Bank Name:</strong> Cross River Bank
                                    </CardText>
                                    <CardText>
                                        <strong>Bank Address:</strong> 885 Teaneck Rd, Teaneck, NJ 07666
                                    </CardText>
                                </>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default UserDetails;
