import React from 'react'
import Chart from 'react-apexcharts'
import { NumericFormat } from 'react-number-format'
import { Card, CardBody } from 'reactstrap'
const RadialBarChart = ({ title, usage, limit }) => {
    const calcPercent = (limit, usage) => {
        return (limit > 0) ? parseInt((usage / limit) * 100) : 0
    }

    const options1 = {
        chart: {
            height: 120,
            type: 'radialBar'
        },
        series: [calcPercent(limit, usage)],
        colors: ['#727cf5'],
        plotOptions: {
            radialBar: {
                hollow: {
                    size: '55%'
                },
                startAngle: -135,
                endAngle: 135,
                track: {
                    background: '#333',
                    startAngle: -135,
                    endAngle: 135,
                },
                dataLabels: {
                    name: {
                        show: false
                    },
                    value: {
                        fontSize: '18px',
                        show: true
                    }
                },
            }
        },
        fill: {
            type: 'solid',
            colors: '#0136e5'
        },
        stroke: {
            lineCap: 'round'
        },
        labels: ['Progress']
    }

    return (
        <Card>
            <CardBody style={{ paddingBottom: '20px' }}>
                <h4 className='header-title mb-3'>{title}</h4>
                <Chart
                    options={options1}
                    series={options1.series}
                    type='radialBar'
                    height={120}
                    className='apex-charts'
                />
                <p className='text-center'>
                    {limit === 0 ?
                        "Not Set"
                        :
                        <>
                            <NumericFormat
                                value={usage}
                                displayType="text"
                                thousandSeparator
                                prefix="$"
                                decimalScale={2}
                                fixedDecimalScale={true}
                                readOnly
                            />
                            {" "}/{" "}
                            <NumericFormat
                                value={limit}
                                displayType="text"
                                thousandSeparator
                                prefix="$"
                                decimalScale={2}
                                fixedDecimalScale={true}
                                readOnly
                            />
                        </>
                    }
                </p>
            </CardBody>
        </Card>
    )
}

export default RadialBarChart