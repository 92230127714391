import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';

const UserDetailsActionBar = ({
    onEditUser,
    onAdjustBalance,
    onChangeCardStatus,
    onAddToCRB,
    onAddToGalileo,
    userData,
}) => {
    const history = useHistory();

    const onViewCard = () => {
        history.push(`/users/view/${userData.id}/galileo`);
    };

    const onViewBankAccounts = () => {
        history.push(`/users/view/${userData.id}/bankaccounts`);
    };

    const onViewStatements = () => {
        history.push(`/users/view/${userData.id}/statements`);
    };

    const onViewcashback = () => {
        history.push(`/users/view/${userData.id}/cashback`);
    };

    return (
        <div className="position-sticky top-0 bg-light p-3 shadow-sm mb-4 z-index-1000">
            <ButtonGroup>
                <Button color="primary" className="mr-1" onClick={onEditUser}>
                    Edit User
                </Button>
                <Button color="primary" className="mr-1" onClick={onAdjustBalance}>
                    Adjust Balance
                </Button>
                {userData.card?.prnNumber && (
                    <>
                        <Button className="mr-1" color="primary" onClick={onViewCard}>
                            {' '}
                            Galileo Details
                        </Button>
                    </>
                )}
                {!userData.crossRiverCustomer && (
                    <Button className="mr-1" color="primary" onClick={onAddToCRB}>
                        Add to CRB
                    </Button>
                )}
                {!userData.card?.prnNumber && (
                    <Button className="mr-1" color="primary" onClick={onAddToGalileo}>
                        Add to Galileo
                    </Button>
                )}

                <Button className="mr-1" color="primary" onClick={onViewBankAccounts}>
                    Bank Accounts
                </Button>

                <Button className="mr-1" color="primary" onClick={onViewStatements}>
                    Statements
                </Button>

                <Button className="mr-1" color="primary" onClick={onViewcashback}>
                    Cashback Details
                </Button>
            </ButtonGroup>
        </div>
    );
};

export default UserDetailsActionBar;
