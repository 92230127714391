import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmModal = ({ isOpen, onClose, onYes, children, modalTitle, disabled = false }) => {
    return (
        <Modal isOpen={isOpen} toggle={onClose} backdrop="static">
            <ModalHeader toggle={onClose}>{modalTitle}</ModalHeader>
            <ModalBody>{children}</ModalBody>
            <ModalFooter>
                <Button onClick={onYes} disabled={disabled}>
                    Yes
                </Button>{' '}
                <Button color="secondary" onClick={onClose} disabled={disabled}>
                    No
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ConfirmModal;