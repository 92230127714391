import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';

import Enums from '../constants/enums';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';
import GalileoSimulator from '#pages/administravia/GalileoSimulator/GalileoSimulator.js';

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const LoginVerify = React.lazy(() => import('../pages/auth/LoginVerify'));
const LoginToken = React.lazy(() => import('../pages/auth/LoginToken'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));

const Bookmarkers = React.lazy(() => import('../pages/settings/Bookmarkers'));
const BoostSpeed = React.lazy(() => import('../pages/settings/BoostSpeed'));
const AdminUsers = React.lazy(() => import('../pages/administravia/AdminUsers/Users'));
const StateManagement = React.lazy(() => import('../pages/settings/States'));
const CrbWebhook = React.lazy(() => import('../pages/settings/CrbWebhook'));
const Authorizations = React.lazy(() => import('../pages/inspect/Authorizations'));
const UsersPage = React.lazy(() => import('#pages/users/list.js'));
const WebhooksPage = React.lazy(() => import('#pages/webhooks/list.js'));
const AffiliatesPage = React.lazy(() => import('../pages/settings/Affiliates'));
const ErrorHistoryPage = React.lazy(() => import('../pages/resource/ErrorHistory'));
const GalileoTransactionPage = React.lazy(() => import('../pages/resource/GalileoTransaction'));
const AchLimitsPage = React.lazy(() => import('../pages/resource/AchLimit'));
const AchQueuePage = React.lazy(() => import('../pages/inspect/AchQueue'));
const FinancialMetricsPage = React.lazy(() => import('#pages/dashboard/financialMetrics.js'));
const VSSReportsPage = React.lazy(() => import('#pages/dashboard/VSSReports.js'));
const AffiliateUsersReportsPage = React.lazy(() => import('#pages/dashboard/affiliateUserReport.js'));
const AchRequestPage = React.lazy(() => import('../pages/inspect/AchRequest'));
const UserTierPage = React.lazy(() => import('../pages/settings/UserTier'));

// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            if (!isUserAuthenticated()) {
                window.location.href = '/account/login';
                return '';
                // not logged in so redirect to login page with the return url
                // return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }

            const loggedInUser = getLoggedInUser();
            // check if route is restricted by role
            if (roles && roles.indexOf(loggedInUser.role) === -1) {
                // role not authorised so redirect to home page
                return <Redirect to={{ pathname: '/' }} />;
            }

            // authorised so return component
            return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute,
};

const dashboardRoutes = {
    name: 'Dashboards',
    route: PrivateRoute,
    roles: [Enums.UserRole.SUPPORT],
    icon: 'fa-solid fa-chart-mixed-up-circle-dollar',
    children: [
        {
            path: '/dashboard/',
            name: 'Financials',
            component: FinancialMetricsPage,
        },
        {
            path: '/VSS',
            name: 'VSS',
            component: VSSReportsPage,
        },
        {
            path: '/affiliateusers',
            name: 'Affiliate Users',
            component: AffiliateUsersReportsPage,
        },
    ],
};

const appsettingsRoutes = {
    path: '/settings',
    name: 'Application settings',
    route: PrivateRoute,
    roles: [Enums.UserRole.SUPPORT],
    icon: 'fa-solid fa-sliders',
    children: [
        {
            path: '/settings/bookmakers',
            name: 'Bookmakers',
            component: Bookmarkers,
            route: PrivateRoute,
        },
        {
            path: '/settings/boost_speeds',
            name: 'Boost Speeds',
            component: BoostSpeed,
            route: PrivateRoute,
        },

        {
            path: '/settings/states',
            name: 'List of States',
            component: StateManagement,
            route: PrivateRoute,
        },
        {
            path: '/settings/crb-webhook',
            name: 'CRB Webhooks',
            component: CrbWebhook,
            route: PrivateRoute,
        },
        {
            path: '/settings/list/affiliates',
            exact: true,
            name: 'Affiliate Manager',
            component: AffiliatesPage,
            route: PrivateRoute,
        },
        {
            path: '/settings/list/ach-limits',
            exact: true,
            name: 'ACH Limits',
            component: AchLimitsPage,
            route: PrivateRoute,
        },
        {
            path: '/settings/list/user-tiers',
            exact: true,
            name: 'User Tiers',
            component: UserTierPage,
            route: PrivateRoute,
        },
    ],
};

const inspectorRoutes = {
    path: '/inspect',
    name: 'Inspectors',
    route: PrivateRoute,
    roles: [Enums.UserRole.SUPPORT],
    icon: 'fa-solid fa-face-thinking',
    children: [
        {
            path: '/inspect/authorizations',
            name: 'Authorizations',
            component: Authorizations,
            route: PrivateRoute,
        },
        {
            path: '/inspect/ach-queue',
            exact: true,
            name: 'ACH Queue',
            component: AchQueuePage,
            route: PrivateRoute,
        },
        {
            path: '/inspect/ach-request',
            exact: true,
            name: 'ACH Request',
            component: AchRequestPage,
            route: PrivateRoute,
        },
        {
            path: '/inspect/error-histories',
            name: 'Error Histories',
            component: ErrorHistoryPage,
            route: PrivateRoute,
        },
        {
            path: '/inspect/old-galileo',
            name: 'Old Galileo Transactions',
            component: GalileoTransactionPage,
            route: PrivateRoute,
        },
    ],
};

const administraviaRoutes = {
    path: '/administravia',
    name: 'Administravia',
    route: PrivateRoute,
    roles: [Enums.UserRole.SUPPORT],
    icon: 'fa-solid fa-skull-crossbones',
    children: [
        {
            path: '/settings/admin_users',
            name: 'Admin Users',
            component: AdminUsers,
            route: PrivateRoute,
        },
        {
            path: '/simulator/galileo',
            name: 'Galileo Simulator',
            component: GalileoSimulator,
            route: PrivateRoute,
        }
    ],
};

const webhookRoutes = {
    path: '/webhooks',
    name: 'Webhooks',
    route: PrivateRoute,
    roles: [Enums.UserRole.SUPPORT],
    icon: 'fa-solid fa-webhook',
    children: [
        {
            path: '/webhooks/galileo',
            name: 'From Galileo',
            component: WebhooksPage,
            route: PrivateRoute,
            props: { source: 'Galileo' },
        },
        {
            path: '/webhooks/stripe',
            name: 'From Stripe',
            component: WebhooksPage,
            route: PrivateRoute,
            props: { source: 'Stripe' },
        },
        {
            path: '/webhooks/crb',
            name: 'From CRB',
            component: WebhooksPage,
            route: PrivateRoute,
            props: { source: 'CrossRiver' },
        },
        {
            path: '/webhooks/all',
            exact: true,
            name: 'All Webhooks',
            component: WebhooksPage,
            route: PrivateRoute,
        },
    ],
};

const userRoute = {
    path: '/users',
    exact: true,
    name: 'User Administration',
    component: UsersPage,
    route: PrivateRoute,
    icon: 'fa-solid fa-users',
    roles: [Enums.UserRole.SUPPORT],
};

const appRoutes = [dashboardRoutes, userRoute, appsettingsRoutes, webhookRoutes, inspectorRoutes, administraviaRoutes];
// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/login-token',
            name: 'LoginToken',
            component: LoginToken,
            route: Route,
        },
        {
            path: '/account/login-verify',
            name: 'LoginVerify',
            component: LoginVerify,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
    ],
};

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            // Sort children alphabetically by name
            item.children.sort((a, b) => a.name.localeCompare(b.name));
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
// const allRoutes = [rootRoute, dashboardRoutes, ...appRoutes, authRoutes, pageRoutes, uiRoutes];
const allRoutes = [authRoutes, rootRoute, ...appRoutes];

// const authProtectedRoutes = [dashboardRoutes, ...appRoutes, pageRoutes, uiRoutes];
const authProtectedRoutes = [...appRoutes];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
