import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Col,
    Row,
} from 'reactstrap';

const ActivateCardModal = ({ isOpen, toggle, cardData, setCardData, handleSubmit }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Activate Card</ModalHeader>
            <Form onSubmit={handleSubmit}>
                <ModalBody>
                    <p>To activate a card, please confirm the following informaiton.</p>
                    <FormGroup>
                        <Row>
                            <Col>
                                <p>
                                    <b>Last 4 Digits of Card #</b>
                                </p>
                                <Input
                                    type="text"
                                    name="lastFour"
                                    id="lastFour"
                                    value={cardData?.lastFour}
                                    onChange={(e) => setCardData({...cardData, lastFour: e.target.value})}
                                    placeholder="1234"
                                    required
                                />
                            </Col>
                            <Col>
                                <p>
                                    <b>Security Code:</b>
                                </p>
                                <Input
                                    type="text"
                                    name="cvv"
                                    id="cvv"
                                    value={cardData?.securityCode}
                                    onChange={(e) => setCardData({...cardData, securityCode: e.target.value})}
                                    placeholder="123"
                                    required
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <p>
                            <b>Expiration Date:</b>
                        </p>
                        <Row>
                            <Col>
                                <Label for="month">Month</Label>
                                <Input
                                    type="text"
                                    name="month"
                                    id="month"
                                    value={cardData?.month}
                                    onChange={(e) => setCardData({ ...cardData, month: e.target.value })}
                                    placeholder="MM"
                                    required
                                />
                            </Col>
                            <Col>
                                <Label for="year">Year</Label>
                                <Input
                                    type="text"
                                    name="year"
                                    id="year"
                                    placeholder="YYYY"
                                    value={cardData?.year}
                                    onChange={(e) => setCardData({ ...cardData, year: e.target.value })}
                                    required
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit">
                        Activate
                    </Button>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default ActivateCardModal;
