import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import BaseAPI from '#helpers/base-api.js';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { changeLayoutTitle } from '../../redux/actions';
import UserDetails from './components/UserDetails.js';
import CardStatusChangeModal from './components/CardStatusChangeModal.js';
import TransactionGrid from './components/TransactionGrid.js';
import EdgeToast from '#components/EdgeToast.js';
import AdjustBalanceModal from './components/AdjustBalanceModal.js';
import Enums from '../../constants/enums';
import CopyButton from '#components/CopyButton.js';

const ViewUserPage = () => {
    const ebAdminClient = new BaseAPI();
    const { user_id } = useParams();
    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [alloyHostedLink, setAlloyHostedLink] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState('success');
    const [toastTitle, setToastTitle] = useState('');
    const [toastContent, setToastContent] = useState('');
    const toggleToast = () => setShowToast(!showToast);

    const [adjustBalanceModalOpen, setAdjustBalanceModalOpen] = useState(false);

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    async function viewBankAccounts() {
        console.log('Looking up bank accounts for: ', user_id);
    }

    async function handleAddToGalileo() {
        try {
            const response = await ebAdminClient.post(`/users/${user_id}/add-to-galileo`);
            console.log(response);
        } catch (error) {
            console.error('Failed to add to Galileo');
            console.log(error);
        }
    }

    async function handleAddToCRB() {
        try {
            console.log('Adding to CRB');
            console.log(user_id);
            const response = await ebAdminClient.post(`/users/${user_id}/add-to-crb`, {
                userId: user_id,
            });
            console.log(response);
            if (response.status === 200) {
                alert('The request was received. this page will reload after 5 seconds. Page is not broken');
                alert('No really I meant it...');
                alert('...really...');
                alert('wait 5 seconds...');
                await delay(5000);
                alert('see I told you...');
                fetchUser(user_id);
                showMessageToast('info', 'CRB', 'Adding to CRB.');
            }
        } catch (error) {
            showMessageToast('error', 'Error', 'Failed to add to CRB');
            console.error('Failed to add to CRB');
            console.log(error);
        }
    }

    async function handleAdjustBalance(amountInDollars, reason, adjustmentType) {
        setIsLoading(true);
        try {
            const adjustBalanceResponse = await ebAdminClient.post(`/users/${user_id}/adjust-balance`, {
                amountInDollars: amountInDollars,
                reason,
                adjustmentType: adjustmentType,
            });
        } catch (error) {
            console.error('Failed to adjust balance');
            console.log(error);
            setIsLoading(false);
            setAdjustBalanceModalOpen(false);
            showMessageToast('error', 'Error', 'Failed to adjust balance');
            return;
        }

        fetchUser(user_id);
        setIsLoading(false);
        setAdjustBalanceModalOpen(false);
    }

    const toggleAdjustBalanceModal = () => {
        setAdjustBalanceModalOpen(!adjustBalanceModalOpen);
    };

    const showMessageToast = (type, title, content) => {
        setToastType(type);
        setToastTitle(title);
        setToastContent(content);
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 10000);
    };

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(changeLayoutTitle('User Details'));
        fetchUser(user_id);
    }, [user_id]);

    /**
     * Fetches user data from the API for a given user ID.
     *
     * @param {string} user_id - The ID of the user to fetch.
     * @returns {Promise<void>}
     */
    async function fetchUser(user_id) {
        setIsLoading(true);
        try {
            const response = await ebAdminClient.get(`users/view/${user_id}`);
            const resp = await ebAdminClient.get(`users/${user_id}/galileo-cards/limits`);
            if (resp.data) {
                const getCardLimits = () => {
                    const limits = resp.data.reduce((acc, card) => {
                        // Ensure both daily and monthly fields exist
                        if (!acc.daily) acc.daily = {};
                        if (!acc.monthly) acc.monthly = {};

                        // Check each element in auth_controls to determine if it's daily or monthly
                        card?.accountLimits?.auth_controls?.forEach((control) => {
                            const limitControlId = control.control_id;
                            const limitAmount = control.amount;
                            const limitAmountUsed = control.amount_used;
                            const limitAmountAvailable = control.amount_available;

                            // Determine limit type based on control ID
                            if (limitControlId === 3) {
                                // Control ID 3 is daily
                                acc.daily[card.prnNumber] = {
                                    controlId: limitControlId,
                                    amount: limitAmount,
                                    amountUsed: limitAmountUsed,
                                    amountAvailable: limitAmountAvailable,
                                };
                            } else if (limitControlId === 8) {
                                // Control ID 8 is monthly
                                acc.monthly[card.prnNumber] = {
                                    controlId: limitControlId,
                                    amount: limitAmount,
                                    amountUsed: limitAmountUsed,
                                    amountAvailable: limitAmountAvailable,
                                };
                            }
                        });

                        return acc;
                    }, {});
                    return limits;
                };
                if (response.data) {
                    response.data.limits = getCardLimits();
                    setUserData(response.data);
                    if (response.data.alloyStatus === 'pending_step_up') {
                        const resp = await ebAdminClient.get(`users/alloy-journey-link/${response.data.alloyJourneyToken}`);
                        setAlloyHostedLink(resp.data.link);
                    }
                } else {
                    console.log('No user found for this id', user_id);
                }
            }
            
        } catch (error) {
            console.error('Could not find user', user_id, error);
        }
        setIsLoading(false);
    }

    const handleCardStatusChange = async (newCardStatus, reason) => {
        try {
            setIsOpen(false);

            const response = await ebAdminClient.post(`users/${user_id}/galileo-status`, {
                active: newCardStatus == Enums.GalileoCardStatus.ACTIVE ? true : false,
                reason,
            });

            // console.log(response);
            if (response.status === 200) {
                fetchUser(user_id); // Refresh user data if match was successful
                showMessageToast('info', 'Galileo Card Status', 'Changing Galileo Card Status.');
            }
        } catch (error) {
            console.error('Failed to change Galileo card status:', error);
            alert('Failed to change Galileo card status.');
        }
    };

    if (isLoading) return <div>Loading...</div>;

    return (
        <div className="py-3">
            <Helmet>
                <title>{userData.email ? `${userData.email} Details` : 'Loading...'}</title>
            </Helmet>

            <UserDetails
                userData={userData}
                onChangeCardStatus={() => {
                    setIsOpen(true);
                }}
                alloyHostedLink={alloyHostedLink}
                onAdjustBalance={toggleAdjustBalanceModal}
                onAddToCRB={handleAddToCRB}
                onAddToGalileo={handleAddToGalileo}
                onViewBankAccounts={viewBankAccounts}
            />

            <TransactionGrid userId={user_id} />

            <EdgeToast
                isOpen={showToast}
                toggle={toggleToast}
                type={toastType}
                title={toastTitle}
                content={toastContent}
            />

            <CardStatusChangeModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                onYes={handleCardStatusChange}
                card={userData.card}
            />

            <AdjustBalanceModal
                isOpen={adjustBalanceModalOpen}
                toggle={toggleAdjustBalanceModal}
                onAdjustBalance={handleAdjustBalance}
                isLoading={isLoading}
            />
        </div>
    );
};

export default ViewUserPage;
