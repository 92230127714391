import React from 'react';
import { Button, Card, CardBody, Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";

const ChangeCardStatus = ({ simulatorEnabled, cardStatusFormik, prnNumber }) => {
    return (
        <Card className="shadow border-0 mt-4">
            <CardBody>
                <h4 className="header-title">Change Card Status</h4>
                <Form onSubmit={cardStatusFormik.handleSubmit}>
                    <FormGroup className="position-relative">
                        <Label for="active">Card Status</Label>
                        <Input
                            id="active"
                            name="active"
                            type="select"
                            className="form-control"
                            onChange={cardStatusFormik.handleChange}
                            onBlur={cardStatusFormik.handleBlur}
                            invalid={cardStatusFormik.errors.active && cardStatusFormik.touched.active}>
                            <option value>Active</option>
                            <option value={false}>Inactive</option>
                        </Input>
                        {cardStatusFormik.errors.active && cardStatusFormik.touched.active ? (
                            <FormFeedback
                                invalid={
                                    cardStatusFormik.errors.active && cardStatusFormik.touched.active
                                }
                                tooltip>
                                {cardStatusFormik.errors.active}
                            </FormFeedback>
                        ) : null}
                    </FormGroup>
                    <Button type="submit" color="dark" disabled={cardStatusFormik.errors.active || prnNumber === '' || !simulatorEnabled}>
                        Submit
                    </Button>
                </Form>
            </CardBody>
        </Card>
    )
}

export default ChangeCardStatus;