import EdgeToast from '#components/EdgeToast.js';
import BaseAPI from '#helpers/base-api.js';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { NumericFormat } from 'react-number-format';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { changeLayoutTitle } from '../../redux/actions';
import ACHSweepModal from './components/ACHSweepModal';

const BankAccountsPage = () => {
    const ebAdminClient = new BaseAPI();
    const { user_id } = useParams();
    const [userData, setUserData] = useState({});
    const [bankAccounts, setBankAccounts] = useState([]);
    const [showACHSweepModal, setShowACHSweepModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState('success');
    const [toastTitle, setToastTitle] = useState('');
    const [toastContent, setToastContent] = useState('');
    const toggleToast = () => setShowToast(!showToast);

    const showMessageToast = (type, title, content) => {
        setToastType(type);
        setToastTitle(title);
        setToastContent(content);
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 10000);
    };

    const runRefreshBankBalance = async () => {
        try {
            const response = await ebAdminClient.get(`users/${user_id}/bank-accounts`);
            if (response.data) {
                setBankAccounts(response.data);
                showMessageToast('success', 'Bank Accounts', 'Bank accounts refreshed successfully');
            } else {
                showMessageToast('error', 'Bank Accounts', 'Failed to refresh bank accounts');
            }
        } catch (error) {
            showMessageToast('error', 'Bank Accounts', 'Failed to refresh bank accounts');
        }
    };

    const runIdentityMatch = async (bankaccountId) => {
        try {
            const response = await ebAdminClient.post(`plaid/identity-match/${bankaccountId}`);
            if (response.data) {
                showMessageToast('success', 'Identity Match', 'Identity match completed successfully');
                await fetchBankAccounts();
            } else {
                showMessageToast('error', 'Identity Match', 'Failed to complete identity match');
            }
        } catch (error) {
            showMessageToast('error', 'Identity Match', 'Failed to complete identity match');
        }
    };

    const onBackToUserDetails = () => {
        history.push(`/users/view/${user_id}`);
    };

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(changeLayoutTitle('Bank Accounts'));
        fetchUser();
        fetchBankAccounts();
    }, [user_id]);

    async function fetchUser() {
        setIsLoading(true);
        try {
            const response = await ebAdminClient.get(`users/view/${user_id}`);
            if (response.data) {
                setUserData(response.data);
            } else {
                console.log('No user found for this id', user_id);
            }
        } catch (error) {
            console.error('Could not find user', user_id, error);
        }
        setIsLoading(false);
    }

    async function fetchBankAccounts() {
        setIsLoading(true);
        try {
            const response = await ebAdminClient.get(`users/${user_id}/bankaccounts`);
            if (response.data) {
                console.log(response.data);
                setBankAccounts(response.data);
            } else {
                console.log('No bank accounts found for this user', user_id);
                showMessageToast('warning', 'No Accounts', 'No bank accounts found for this user');
            }
        } catch (error) {
            console.error('Could not fetch bank accounts', error);
            showMessageToast('error', 'Error', 'Failed to fetch bank accounts');
        }
        setIsLoading(false);
    }

    if (isLoading) return <div>Loading...</div>;

    return (
        <div className="py-3">
            <Helmet>
                <title>Bank Accounts:</title>
            </Helmet>
            <div className="mb-3">
                <button className="btn btn-warning" onClick={onBackToUserDetails}>
                    Back To {userData.name} Details
                </button>
            </div>
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">Bank Accounts for </h4>
                    <h5 className="card-subtitle mb-2 text-muted">{userData.name}</h5>
                    <h6 className="card-subtitle mb-2 text-muted">{userData.email}</h6>
                    <div className="d-flex justify-content-end mb-2">
                        {/* <button className="btn btn-secondary mr-3" onClick={() => setShowACHSweepModal(true)}>
                            Initiate ACH Sweep
                        </button> */}
                        <button className="btn btn-outline-primary" onClick={fetchBankAccounts}>
                            <i className="fa-solid fa-refresh mr-1" />
                            Refresh
                        </button>
                    </div>
                    {bankAccounts.length > 0 ? (
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Bank Name</th>
                                    <th>Account Name</th>
                                    <th>Account Number (last 4 digits)</th>
                                    <th>Latest Balance</th>
                                    <th> Identity Match </th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bankAccounts.map((bankAccount, index) => (
                                    <tr key={index}>
                                        <td>{bankAccount.metaBankName}</td>
                                        <td>{bankAccount.metaAccountName}</td>
                                        <td>{bankAccount.metaAccountMask}</td>
                                        <td>
                                            <NumericFormat
                                                value={bankAccount.availableBalance}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'$'}
                                                decimalScale={2}
                                            />
                                        </td>
                                        <td>
                                            {bankAccount.identityMatch &&
                                            bankAccount.identityMatch !== null &&
                                            Object.keys(bankAccount.identityMatch).length > 0 ? (
                                                <div>
                                                    <div>
                                                        Legal Name Score: {bankAccount.identityMatch.legal_name_score}
                                                    </div>
                                                    <div>
                                                        Postal Code Match:{' '}
                                                        {bankAccount.identityMatch.is_postal_code_match ? 'Yes' : 'No'}
                                                    </div>
                                                    <div>Address Score: {bankAccount.identityMatch.address_score}</div>
                                                    <div>
                                                        Email Score: {bankAccount.identityMatch.email_address_score}
                                                    </div>
                                                    <div>
                                                        Nickname Match:{' '}
                                                        {bankAccount.identityMatch.is_nickname_match ? 'Yes' : 'No'}
                                                    </div>
                                                    <div>
                                                        Phone Number Score:{' '}
                                                        {bankAccount.identityMatch.phone_number_score}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </td>
                                        <td>
                                            {bankAccount.deleted ? `Deleted on ${bankAccount.deletedAt}` : 'Active'}
                                        </td>
                                        <td>
                                            {!bankAccount.deleted && (
                                                <button
                                                    className="btn btn-sm btn-primary"
                                                    onClick={() => runIdentityMatch(bankAccount.id)}>
                                                    Run IdentityMatch
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>No bank accounts found</p>
                    )}
                </div>
            </div>
            <ACHSweepModal
                isOpen={showACHSweepModal}
                onClose={() => setShowACHSweepModal(!showACHSweepModal)}
                bankData={bankAccounts}
                userData={user_id}
                debitAmount={userData.debitAmount}
                creditAmount={userData.creditAmount}
                showMessageToast={showMessageToast}
            />
            <EdgeToast
                isOpen={showToast}
                toggle={toggleToast}
                type={toastType}
                title={toastTitle}
                content={toastContent}
            />
        </div>
    );
};

export default BankAccountsPage;
