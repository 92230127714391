import React from 'react';
import { Progress } from 'reactstrap';

/**
 * Renders the preloader
 */
const Loader = ({ title }) => {
    return (
        <div className="loading">
            <div>
                <h2>{title}...</h2>
                <Progress animated value={50} color="info" className="mt-3" />
            </div>
        </div>
    );
};

export default Loader;
