import React from 'react';
import { Button, Card, CardBody, Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";

const SimulateCardSettlement = ({ simulatorEnabled, cardSettlementFormik, prnNumber }) => {
    return (
        <Card className="shadow border-0 mt-4">
            <CardBody>
                <h4 className="header-title">Create Simulated Card Settlement</h4>
                <Form onSubmit={cardSettlementFormik.handleSubmit}>
                    <FormGroup className="position-relative">
                        <Label for="association">Association (visa, mc_auth)</Label>
                        <Input
                            id="association"
                            name="association"
                            type="text"
                            placeholder="Association"
                            value={cardSettlementFormik.values.association}
                            onChange={cardSettlementFormik.handleChange}
                            onBlur={cardSettlementFormik.handleBlur}
                            invalid={
                                cardSettlementFormik.errors.association &&
                                cardSettlementFormik.touched.association
                            }
                        />
                        {cardSettlementFormik.errors.association &&
                            cardSettlementFormik.touched.association ? (
                            <FormFeedback
                                invalid={
                                    cardSettlementFormik.errors.association &&
                                    cardSettlementFormik.touched.association
                                }
                                tooltip>
                                {cardSettlementFormik.errors.association}
                            </FormFeedback>
                        ) : null}
                    </FormGroup>
                    <FormGroup className="position-relative">
                        <Label for="authId">Authorization ID (From Authorization Response)</Label>
                        <Input
                            id="authId"
                            name="authId"
                            label="Authorization Id (From Authorization Response)"
                            type="text"
                            placeholder="Authorization Id"
                            onChange={cardSettlementFormik.handleChange}
                            onBlur={cardSettlementFormik.handleBlur}
                            value={cardSettlementFormik.values.authId}
                            invalid={
                                cardSettlementFormik.errors.authId &&
                                cardSettlementFormik.touched.authId
                            }
                        />
                        {cardSettlementFormik.errors.authId && cardSettlementFormik.touched.authId ? (
                            <FormFeedback
                                invalid={
                                    cardSettlementFormik.errors.authId &&
                                    cardSettlementFormik.touched.authId
                                }
                                tooltip>
                                {cardSettlementFormik.errors.authId}
                            </FormFeedback>
                        ) : null}
                    </FormGroup>
                    <FormGroup className="position-relative">
                        <Label for="mcc2">MCC</Label>
                        <Input
                            id="mcc2"
                            name="mcc"
                            type="text"
                            placeholder="MCC"
                            onChange={cardSettlementFormik.handleChange}
                            onBlur={cardSettlementFormik.handleBlur}
                            value={cardSettlementFormik.values.mcc}
                            invalid={
                                cardSettlementFormik.errors.mcc && cardSettlementFormik.touched.mcc
                            }
                        />
                        {cardSettlementFormik.errors.mcc && cardSettlementFormik.touched.mcc ? (
                            <FormFeedback
                                invalid={
                                    cardSettlementFormik.errors.mcc && cardSettlementFormik.touched.mcc
                                }
                                tooltip>
                                {cardSettlementFormik.errors.mcc}
                            </FormFeedback>
                        ) : null}
                    </FormGroup>
                    <Button
                        type="submit"
                        color="dark"
                        disabled={
                            cardSettlementFormik.errors.association ||
                            cardSettlementFormik.errors.authId ||
                            cardSettlementFormik.mcc || 
                            prnNumber === '' ||
                            !simulatorEnabled
                        }>
                        Submit
                    </Button>
                </Form>
            </CardBody>
        </Card>
    )
}

export default SimulateCardSettlement;