// reducers/filterReducer.js
import { SAVE_FILTER_STATE } from './actions';

const initialState = {
  users: {},
  transactions_all: {},
  transactions_settled: {},
  transactions_authorizations: {},
  transactions_payments: {},
  transactions_achRequests: {},
  transactions_userView: {},
  webhooks_all: {},
  webhooks_galileo: {},
  webhooks_crossriver: {},
  webhooks_stripe: {},
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_FILTER_STATE:
      const { grid, filterState } = action.payload;
      return {
        ...state,
        [grid]: filterState,
      };
    default:
      return state;
  }
};

export default filterReducer;
